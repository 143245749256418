@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f5f5f5;
}

.content {
  position: absolute;
  top: 0;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #7C9184cc;
}